import { createApiRef } from '@backstage/core-plugin-api';
import React from 'react';

export interface MonitoringMetricsPluginApi {
  getMetrics(system: object | undefined): Promise<Resources>;
  getSummary(): Promise<any>;
  getDates(): Promise<string[]>;
  getSummary(): Promise<MonitoringSummary>;
  getTagMetrics(): Promise<CompleteFileTagResourceMetrics>;
}

export const myPluginApiRef = createApiRef<MonitoringMetricsPluginApi>({
  id: 'plugin.monitoring-metrics.service',
});

export interface RequestBody {
  date: string;
  tags: string[];
}

export type Resources = {
  date: string;
  tags: string[];
  results: FormedResponseObject;
};

export type MonitoringSummary = {
  date: string;
  tagged: any;
  untagged: any;
};

export interface FormedResponseObject {
  formedTeamResponseContainer: { items: RowFormedObject[]; date: string };
  formedNoTagResponseContainer: {
    items: RowFormedObject[];
    date: string;
  };
}

export interface RowFormedObject {
  id?: string;
  name?: string;
  env: string;
  type: ResourceList;
  status: 'ERROR' | 'PASSED';
  region: string;
  value: any;
  error?: {
    _original: any;
    value: any;
    details: any[];
  };
  details?: React.JSX.Element;
  alert_counter?: number;
}

export interface ResourceObject {
  region: string;
  env: string;
  value: any;
  error?: any;
}

export interface ResourceName {
  [resourceName: string]: {
    passed: ResourceObject[];
    error: ResourceObject[];
  };
}

export interface FileObject {
  date: string;
  tagged: {
    [teamName: string]: ResourceName;
  };
  untagged?: {
    [resourceName: string]: ResourceObject[];
  };
}

export type Filters = {
  env?: string;
  status?: string;
  region?: string;
  type?: string;
  viewUntaggedResources?: string;
};

export type DenseTableProps = {
  teamResources: RowFormedObject[];
  noTaggedResources?: RowFormedObject[];
  valueToSearch: any;
  env: string[];
  status: string[];
  regionList: string[];
  resourceList: string[];
  logs?: any;
  filtersList?: Filters;
  setFilter: (filterName: string, filterValue: string) => void;
};

export type ResourceList =
  | 'apiGateway'
  | 'cloudFormation'
  | 'cloudfront'
  | 'dynamoDB'
  | 'ec2'
  | 'lambda'
  | 'rds'
  | 's3Bucket';

export interface PropsDropDownPicker {
  label: string;
  itemList: string[];
  changeSelectedValue: (e: any) => void;
  value?: string;
}

export interface FilterDropDown {
  value?: string;
  label: string;
  actionOnChange: (filterName: string, filterValue: string) => void;
  itemList: string[];
  key: string;
}

export interface ErrorTable {
  id?: string;
  criticality?: 'LOW' | 'MEDIUM' | 'HIGH' | 'CRITICAL';
  message: string;
  humanMessage: string;
  url?: string;
}

export type CompleteFileTagResourceMetrics = {
  dev: TagResourcesMetrics[];
  test: TagResourcesMetrics[];
  prod: TagResourcesMetrics[];
};

export type DetailTagResourceMetric = {
  [resourceName: string]: {
    totalResources: number;
    resourcesWithoutTeamTag: number;
    untaggedResources: number;
  };
};

export type TagResourcesMetrics = {
  region: string;
  stats: {
    details: DetailTagResourceMetric;
    resourcesWithoutTeamTag: number;
    totalResources: number;
    untaggedResources: number;
  };
};

import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Typography from '@material-ui/core/Typography';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { DocsIcon, SupportButton } from '@backstage/core-components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    headerCell: {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
    },
  }),
);

export default {
  title: 'Layout/Dialog',
  component: Dialog,
};

const rows = [
  {
    level: 'Elite',
    deploymentFrequency: 'Once a day or more',
    changeLeadTime: 'Less than one day',
    changeFailureRate: '5%',
    meanTimeToRepair: 'Less than one hour',
  },
  {
    level: 'High',
    deploymentFrequency: 'Between once per day and once per week',
    changeLeadTime: 'Between one day and one week',
    changeFailureRate: '5-10%',
    meanTimeToRepair: 'Less than one day',
  },
  {
    level: 'Medium',
    deploymentFrequency: 'Between once per week and once per month',
    changeLeadTime: 'Between one week and one month',
    changeFailureRate: '10-15%',
    meanTimeToRepair: 'Less than one week',
  },
  {
    level: 'Low',
    deploymentFrequency: 'Less than once a month',
    changeLeadTime: 'More than a month',
    changeFailureRate: '> 15%',
    meanTimeToRepair: 'More than a week',
  },
];

export const InfoDialog = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };
  const dialogContent = () => {
    return (
      <>
        <Typography>
          DORA metrics are a set of four key metrics that help teams measure the
          performance of their software delivery process.
        </Typography>

        <Typography>
          These metrics should be treated by teams as a guiding light to help
          you understand how your team is performing across different areas of
          software delivery.
        </Typography>

        <Typography>
          To get more information on the different grading levels for each or
          for more information on how to improve the metrics, you can visit out
          documentation below.
        </Typography>
        <TableContainer
          style={{ marginTop: '20px', marginBottom: '20px' }}
          component={Paper}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {[
                  'Performance level',
                  'Deployment Frequency',
                  'Change lead time',
                  'Change failure rate',
                  'Mean time to repair',
                ].map(header => (
                  <TableCell
                    className={classes.headerCell}
                    align="left"
                    key={header}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.level}>
                  <TableCell component="th" scope="row">
                    {row.level}
                  </TableCell>
                  <TableCell align="left">{row.deploymentFrequency}</TableCell>
                  <TableCell align="left">{row.changeLeadTime}</TableCell>
                  <TableCell align="left">{row.changeFailureRate}</TableCell>
                  <TableCell align="left">{row.meanTimeToRepair}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="h6">Can't see all your metrics?</Typography>
        <Typography>
          If you are not seeing your deployments or builds coming through
          properly, you may need to configure your components using the correct
          annotations as shown in the documentation.
        </Typography>
      </>
    );
  };

  return (
    <>
      <IconButton size="small" onClick={openDialog}>
        <InfoOutlined />
      </IconButton>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={closeDialog}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">
          What are DORA metrics?
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{dialogContent()}</DialogContent>
        <DialogActions>
          <Button
            startIcon={<DocsIcon />}
            color="primary"
            href="https://sanofi.atlassian.net/wiki/spaces/ACCEL/pages/63882330345/DORA+metrics"
            target="_blank"
          >
            More info about DORA
          </Button>
          <Button
            startIcon={<DocsIcon />}
            color="primary"
            href={`https://backstage.${
              process.env.NODE_ENV === 'test' ? 'test' : 'prod'
            }.accelerator.sanofi/docs/default/system/platform-backstage/plugins/dora-deployment-metrics/#configuring-your-dora-deployment-metrics`}
            target="_blank"
          >
            How to annotate your components
          </Button>
          <SupportButton title="Contact the Platform team" />
        </DialogActions>
      </Dialog>
    </>
  );
};

import { CompoundEntityRef } from '@backstage/catalog-model';
import {
  DEFAULT_FILTER_ENTRIESV1,
  FilterListType,
  KeyValueType,
} from './api/backend';

export const getDoraSettingsRef = (compoundEntity: CompoundEntityRef): string =>
  (Object.keys(compoundEntity) as Array<keyof CompoundEntityRef>)
    .map((key: keyof CompoundEntityRef) => `${key}:${compoundEntity[key]}`)
    .join('/');

export const cleanQueryString = (str: string) => {
  let res = str.replace(/\s+/g, ' ').trim();
  res = res.replace(/(\r\n|\n|\r)/gm, '');

  return res;
};

export const groupFilterByName = (filters: Array<KeyValueType>) =>
  filters.reduce((acc: Record<string, string[]>, filter: KeyValueType) => {
    const { key, value } = filter;
    if (acc[key]) {
      acc[key].push(value);
    } else {
      acc[key] = [value];
    }
    return acc;
  }, {});

export const filterEmptyValuesAndApplyDefaults = (entries: FilterListType) => {
  const data = {} as FilterListType;
  Object.keys(entries).forEach(entryKey => {
    const filtered = entries[entryKey as keyof FilterListType].filter(
      (entry: KeyValueType) =>
        entry.key.trim() !== '' ||
        (entry.value.trim() !== '' && !entry.options?.allowEmptyValue),
    );

    if (!filtered.length) {
      data[entryKey as keyof FilterListType] =
        DEFAULT_FILTER_ENTRIESV1[entryKey as keyof FilterListType];
    } else {
      data[entryKey as keyof FilterListType] = filtered;
    }
  });

  return { ...DEFAULT_FILTER_ENTRIESV1, ...data };
};

import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  createScaffolderLayout,
  LayoutTemplate,
} from '@backstage/plugin-scaffolder-react';
import { Card, CardContent, Grid } from '@material-ui/core';
import React from 'react';
import { InfoCard, MarkdownContent } from '@backstage/core-components';

const mdMissingContent = `
# Missing Desc

To use this part, you should have a 'description' block in your parameters, The format expected is markdown's style.

The first line should contain a title and will be used as it is (the # sign will be remove and this line won't be part of the content).
`;

const CreateFrontTemplate: LayoutTemplate = ({ properties, description }) => {
  return (
    <Grid container justifyContent="flex-end" direction="row" spacing={2}>
      <Grid item xs={7}>
        <Card>
          <CardContent>
            {properties.map(prop => (
              <Grid
                style={{ marginTop: 2, marginBottom: 10 }}
                key={prop.content.key}
              >
                {prop.content}
              </Grid>
            ))}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={5}>
        <InfoCard title={description?.split('\n')[0].slice(1)}>
          <CardContent>
            <MarkdownContent
              content={
                description?.split('\n').slice(1).join('\n') || mdMissingContent
              }
              dialect="common-mark"
            />
          </CardContent>
        </InfoCard>
      </Grid>
    </Grid>
  );
};

export const TemplateWorkflowTwoCol = scaffolderPlugin.provide(
  createScaffolderLayout({
    name: 'CreateFrontWFTemplate',
    component: CreateFrontTemplate,
  }),
);

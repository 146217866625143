import React from 'react';

import { ScaffolderPage as ScaffolderPageDefault } from '@backstage/plugin-scaffolder';

const headerOptions = {
  pageTitleOverride: 'Scaffolder',
  title: 'Backstage workflows',
  subtitle:
    'These workflows allow you to deploy a new project or integrate Backstage into your existing project.',
};

// Note: Workflows must have one unique tag to fall under a group and can have any number of alternative tags to describe the workflow
export const ScaffolderPage = (
  <ScaffolderPageDefault
    headerOptions={headerOptions}
    groups={[
      {
        title: 'Golden paths for deploying new services',
        filter: entity =>
          entity?.metadata?.tags?.includes('golden-path') ?? false,
      },
      {
        title: 'Integrate Backstage into your project',
        filter: entity =>
          entity?.metadata?.tags?.includes('backstage-integration') ?? false,
      },
      {
        title: 'Compliance',
        filter: entity =>
          entity?.metadata?.tags?.includes('compliance') ?? false,
      },
      {
        title: 'Available 3rd party platforms',
        filter: entity =>
          entity?.metadata?.tags?.includes('tools-configuration') ?? false,
      }
    ]}
  />
);

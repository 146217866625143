import { CompoundEntityRef } from '@backstage/catalog-model';
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { groupBy } from 'lodash';
import {
  Config,
  KeyValue,
  DoraApiResult,
} from '@internal/backstage-plugin-dora-metrics-backend';

export const DEFAULT_FILTER_ENTRIESV1: FilterListType = {
  jiraStartedFilters: [
    {
      key: 'status',
      value: 'In Progress',
    },
    {
      key: 'status',
      value: 'Building',
    },
  ],
  jiraCompletedFilters: [
    {
      key: 'status',
      value: 'Done',
    },
  ],
  pipelineFilters: [],
  branchFilters: [
    {
      key: 'branch',
      value: '',
      options: {
        disableKey: true,
      },
    },
  ],
  queriesFilters: [
    {
      key: 'avgChangeLeadTime',
      value: '',
      originalValue: '',
      options: {
        allowEmptyValue: true,
      },
    },
    {
      key: 'avgBugResolutionTime',
      value: '',
      originalValue: '',
      options: {
        allowEmptyValue: true,
      },
    },
    {
      key: 'highPriorityBugsIntroduced',
      value: '',
      originalValue: '',
      options: {
        allowEmptyValue: true,
      },
    },
  ],
};

export type KeyValueType = {
  key: string;
  value: string;
  originalValue?: string;
  options?: {
    disableKey?: boolean;
    disableValue?: boolean;
    allowEmptyValue?: boolean;
  };
};

export type JiraWorkflowTypes =
  | 'simplified'
  | 'complex'
  | 'custom-jql'
  | 'custom';

export type FilterListType = {
  jiraStartedFilters: KeyValue[];
  jiraCompletedFilters: KeyValue[];
  pipelineFilters: KeyValue[];
  branchFilters: KeyValue[];
  queriesFilters: KeyValue[];
};

export type StoredFilters = {
  workFlowType: JiraWorkflowTypes;
  entries: FilterListType;
};

interface IssueFields {
  resolutiondate: string;
  created: string;
  timeToResolve?: string;
}

export type JiraIssue = {
  id: string;
  key: string;
  self: string;
  expand: string;
  changelog: {
    histories: Array<History>;
  };
  fields: IssueFields;
};

export interface LaunchDarklyFlagResponse {
  _links: {
    self: {
      href: string;
      type: string;
    };
  };
  archived: boolean;
  name: string;
  creationDate: number;
  deprecated: boolean;
  description: string;
  environments: {
    [key: string]: {
      _environmentName: string;
      archived: false;
      lastModified: number;
      on: boolean;
    };
  };
  customProperties?: {
    'jira.issues': {
      name: string;
      value: string[];
    };
  };
}

export interface LaunchDarklyFlagStatus {
  name: 'active' | 'inactive' | 'launched' | 'new';
  lastRequested: null;
  _links: {
    parent: {
      href: string;
      type: string;
    };
    self: {
      href: string;
      type: string;
    };
  };
}

export class DoraMetricsClient {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor({
    discoveryApi,
    fetchApi,
  }: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
  }) {
    this.discoveryApi = discoveryApi;
    this.fetchApi = fetchApi;
  }

  fetchTeamConfiguration = async (entityRef: CompoundEntityRef) => {
    const url = await this.discoveryApi.getBaseUrl('dora-metrics');
    const response = await this.fetchApi.fetch(
      `${url}/${entityRef.namespace}/${entityRef.name}/config`,
    );
    const deserializedResponse = await response.json();
    if (!response.ok) {
      throw new Error(deserializedResponse.error.message);
    }
    return deserializedResponse;
  };

  updateTeamConfiguration = async (
    entityRef: CompoundEntityRef,
    config: { config: [Config] },
  ) => {
    const url = await this.discoveryApi.getBaseUrl('dora-metrics');
    const response = await this.fetchApi.fetch(
      `${url}/${entityRef.namespace}/${entityRef.name}/config`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(config),
      },
    );

    if (!response.ok) {
      throw new Error('Failed to update team configuration');
    }

    return await response.json();
  };

  getLatestDoraMetrics = async (
    entityRef: CompoundEntityRef,
  ): Promise<DoraApiResult> => {
    const url = await this.discoveryApi.getBaseUrl('dora-metrics');
    const response = await this.fetchApi.fetch(
      `${url}/${entityRef.namespace}/${entityRef.name}/latest`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok) {
      throw new Error('Failed to query Dora metrics');
    }

    return await response.json();
  };

  mapOldConfigToNew = (
    oldConfig: StoredFilters,
    defaultBaseQueries: {
      avgChangeLeadTime: string;
      avgBugResolutionTime: string;
      highPriorityBugsIntroduced: string;
    },
  ): Config => {
    const {
      avgBugResolutionTime,
      avgChangeLeadTime,
      highPriorityBugsIntroduced,
    } = groupBy(oldConfig.entries.queriesFilters, 'key');
    const workflowType =
      oldConfig.workFlowType === 'custom-jql'
        ? 'custom'
        : oldConfig.workFlowType;
    return {
      jiraWorkflow: {
        workflowType,
        completedWorkflowCondition: oldConfig.entries.jiraCompletedFilters,
        inProgressWorkflowCondition: oldConfig.entries.jiraStartedFilters,
        queries: {
          avgChangeLeadTime: {
            customJql:
              avgChangeLeadTime[0].value ||
              defaultBaseQueries.avgChangeLeadTime,
          },
          avgBugResolutionTime: {
            customJql:
              avgBugResolutionTime[0].value ||
              defaultBaseQueries.avgBugResolutionTime,
          },
          highPriorityBugsIntroduced: {
            customJql:
              highPriorityBugsIntroduced[0].value ||
              defaultBaseQueries.highPriorityBugsIntroduced,
          },
        },
      },
      deploymentWorkflow: {
        pipelineFilters: oldConfig.entries.pipelineFilters,
        branchFilters: oldConfig.entries.branchFilters.map(
          (x: { value: string }) => ({
            key: 'branch',
            value: x.value || '*',
          }),
        ),
      },
    };
  };
}

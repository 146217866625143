import React from 'react';
import EliteStatus from '@mui/icons-material/VerifiedTwoTone';
import MediumStatus from '@mui/icons-material/TrendingFlatTwoTone';
import OkStatus from '@mui/icons-material/TrendingUpTwoTone';
import LowStatus from '@mui/icons-material/TrendingDownTwoTone';
import { Box, Typography } from '@material-ui/core';
import { BackstagePalette } from '@backstage/theme';

export const DoraLegend = (palette: { palette: BackstagePalette }) => {
  const statusMappings = [
    {
      key: 'Elite',
      component: EliteStatus,
      color: palette?.palette?.status?.ok,
    },
    {
      key: 'High',
      component: OkStatus,
      color: palette?.palette?.status?.running,
    },
    {
      key: 'Medium',
      component: MediumStatus,
      color: palette?.palette?.status?.warning,
    },
    {
      key: 'Low',
      component: LowStatus,
      color: palette?.palette?.status?.error,
    },
  ];

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        maxWidth: '500px',
        justifyContent: 'space-between',
      }}
    >
      {statusMappings.map(mappedValue => (
        <Box
          key={mappedValue.key}
          display="flex"
          flexDirection="row"
          style={{ alignItems: 'center' }}
        >
          <mappedValue.component
            style={{
              color: mappedValue.color,
              marginRight: '5px',
            }}
          />
          <Typography key={mappedValue.key} variant="body2">
            {mappedValue.key}
          </Typography>
        </Box>
      ))}
    </div>
  );
};

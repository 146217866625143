import React, { ChangeEvent, useState } from 'react';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import RestorePage from '@material-ui/icons/RestorePage';
import DoneIcon from '@material-ui/icons/Done';
import Typography from '@material-ui/core/Typography';
import { set } from 'lodash';
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from '@material-ui/core';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Config,
  CustomJqlQuery,
  KeyValue,
} from '@internal/backstage-plugin-dora-metrics-backend';

type ValidationErrors = {
  errors: Array<string>;
  path: string;
};
const uiConfig = {
  disableKey: [
    'jiraWorkflow.completedWorkflowConditions.tags',
    'jiraWorkflow.inProgressWorkflowConditions.tags',
  ],
  disableValue: [],
  hideRemoveButton: ['deploymentWorkflow.deploymentBranch'],
};

type HandleEntryChange = (key: string, value: string | KeyValue[]) => void;

export type JiraWorkflowTypes = 'simplified' | 'complex' | 'custom-jql';

export type FilterListType = {
  jiraStartedFilters: KeyValue[];
  jiraCompletedFilters: KeyValue[];
  pipelineFilters: KeyValue[];
  branchFilters: KeyValue[];
  queriesFilters: KeyValue[];
};

export type StoredFilters = {
  workFlowType: JiraWorkflowTypes;
  entries: FilterListType;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }),
);

export const DEFAULT_TEAM_CONFIG: Config = {
  deploymentWorkflow: {
    pipelineFilters: [
      {
        key: '',
        value: '',
      },
    ],
    branchFilters: [
      {
        key: 'branch',
        value: 'main',
      },
    ],
  },
  jiraWorkflow: {
    workflowType: 'simplified',
    completedWorkflowCondition: [
      {
        key: 'status',
        value: 'Done',
      },
    ],
    inProgressWorkflowCondition: [
      {
        key: 'status',
        value: 'In Progress',
      },
      {
        key: 'status',
        value: 'Building',
      },
    ],
  },
};

export const DEFAULT_FILTER_ENTRIESV2: FilterListType = {
  jiraStartedFilters: [
    {
      key: 'status',
      value: 'In Progress',
    },
    {
      key: 'status',
      value: 'Building',
    },
  ],
  jiraCompletedFilters: [
    {
      key: 'status',
      value: 'Done',
    },
  ],
  pipelineFilters: [],
  branchFilters: [
    {
      key: 'branch',
      value: '*',
    },
  ],
  queriesFilters: [
    {
      key: 'avgChangeLeadTime',
      value: '',
    },
    {
      key: 'avgBugResolutionTime',
      value: '',
    },
    {
      key: 'highPriorityBugsIntroduced',
      value: '',
    },
  ],
};

const KeyValueRow = ({
  title,
  value,
  handleChange,
  disableTitle = false,
  validationErrors,
}: {
  title: string;
  value: string;
  disableTitle?: boolean;
  validationErrors: ValidationErrors;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) => (
  <Box display="flex" flexDirection="column">
    <Grid container alignItems="center" justifyContent="flex-start">
      <Grid item sm={3}>
        <TextField
          disabled={disableTitle}
          value={title}
          fullWidth
          label="Key"
        />
      </Grid>
      <Grid item md={8} sm={7}>
        <TextField
          fullWidth
          label="Value"
          value={value}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
    {validationErrors.errors.map(error => (
      <Typography color="error" variant="caption" key={error}>
        {error}
      </Typography>
    ))}
  </Box>
);

const FilterAccumulator = ({
  handleChange,
  disableRemoveButton = false,
  value,
  target,
  disableInput = false,
  errors,
}: {
  handleChange: HandleEntryChange;
  disableRemoveButton?: boolean;
  value: KeyValue[];
  target: string;
  disableInput?: boolean;
  errors: ValidationErrors[];
}) => {
  const handleListChange = (index: number, entry?: KeyValue) => {
    const updated = [...value];
    if (entry) {
      updated[index] = entry;
    } else {
      updated.splice(index, 1);
    }

    handleChange(target, updated);
  };

  const rows = value.map((entry, index) => {
    const hasError = errors.some(x => x.path.includes(`${target}[${index}]`));
    const rowErrors = errors.find(x => x.path.includes(`${target}[${index}]`));
    return (
      <Box display="flex" flexDirection="column" key={`${target}[${index}]`}>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          spacing={0}
        >
          <Grid item sm={3}>
            <TextField
              value={entry.key}
              fullWidth
              label="Key"
              onChange={e =>
                handleListChange(index, { ...entry, key: e.target.value })
              }
              disabled={disableInput || uiConfig.disableKey.includes(target)}
              error={hasError}
            />
          </Grid>
          <Grid item md={8} sm={7}>
            <TextField
              fullWidth
              label="Value"
              value={entry.value}
              onChange={e =>
                handleListChange(index, { ...entry, value: e.target.value })
              }
              disabled={disableInput}
              error={hasError}
            />
          </Grid>

          {!uiConfig.hideRemoveButton.includes(target) && (
            <Grid item container md={1} direction="row" wrap="nowrap">
              {target === 'queriesFilters' ? (
                <>
                  <Tooltip title="Use default" arrow>
                    <span>
                      <IconButton
                        disabled={disableRemoveButton}
                        onClick={_ => handleListChange(index, entry)}
                      >
                        <RestorePage
                          color={disableRemoveButton ? 'disabled' : 'error'}
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Clear" arrow>
                    <span>
                      <IconButton
                        disabled={disableRemoveButton}
                        onClick={_ =>
                          handleListChange(index, { key: '', value: '' })
                        }
                      >
                        <RemoveIcon
                          color={disableRemoveButton ? 'disabled' : 'error'}
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                </>
              ) : (
                <Tooltip title="Delete filter" arrow>
                  <span>
                    <IconButton
                      disabled={disableRemoveButton}
                      onClick={_ => handleListChange(index)}
                      size="small"
                    >
                      <RemoveIcon
                        color={disableRemoveButton ? 'disabled' : 'error'}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </Grid>
          )}
        </Grid>
        {rowErrors?.errors.map(error => (
          <Typography color="error" variant="caption" key={error}>
            {error}
          </Typography>
        ))}
      </Box>
    );
  });

  return <>{rows}</>;
};

const ItemContainer: React.FC = ({ children }) => (
  <Box marginTop="0.5rem" marginBottom="0.5rem">
    {children}
  </Box>
);

const FilterList = ({
  label,
  target,
  onChange,
  value,
  defaultValue,
  disableChange = false,
  validationErrors,
  options,
}: {
  label: string;
  target: string;
  defaultValue?: KeyValue[];
  onChange: (target: string, value: string | KeyValue[]) => void;
  value: KeyValue[];
  disableChange?: boolean;
  validationErrors: ValidationErrors[];
  options?: { disableRemoveButton: boolean };
}) => {
  const errors = validationErrors?.filter(x => x.path.includes(target));
  const { disableRemoveButton } = options || { disableRemoveButton: false };
  return (
    <ItemContainer>
      <Typography
        variant="subtitle2"
        color="secondary"
        style={{ marginBottom: '0.5rem' }}
      >
        <strong>{label}</strong>
      </Typography>

      <FilterAccumulator
        target={target}
        value={value || defaultValue}
        handleChange={onChange}
        disableInput={disableChange}
        errors={errors}
        disableRemoveButton={disableRemoveButton || disableChange}
      />
      <Box display="flex" justifyContent="center" marginTop={1}>
        <Button
          disabled={disableChange}
          size="small"
          variant="contained"
          color="primary"
          onClick={() => onChange(target, [...value, { key: '', value: '' }])}
        >
          Add filter
        </Button>
      </Box>
    </ItemContainer>
  );
};

const DialogForm = ({
  config,
  handleConfigChange,
  validationErrors,
}: {
  handleConfigChange: (key: string, value: string | KeyValue[]) => void;
  config: Config;
  validationErrors: ValidationErrors[];
}) => {
  return (
    <Box display="flex" justifyContent="space-between" flexDirection="column">
      <Typography variant="h6" color="secondary">
        <strong>Step 1: Choose your deployment workflow</strong>
      </Typography>
      <FilterList
        label="Add the target branches for your project deployments"
        target="deploymentWorkflow.branchFilters"
        onChange={handleConfigChange}
        value={config.deploymentWorkflow.branchFilters}
        validationErrors={validationErrors}
      />
      <FilterList
        target="deploymentWorkflow.pipelineFilters"
        label="Filter your pipelines with extra tags if you have any"
        onChange={handleConfigChange}
        value={config.deploymentWorkflow.pipelineFilters}
        defaultValue={DEFAULT_FILTER_ENTRIESV2.pipelineFilters}
        validationErrors={validationErrors}
      />

      <Typography variant="h6" color="secondary">
        <strong>Step 2: Configure your teams Jira workflow</strong>
      </Typography>

      <ItemContainer>
        <Typography
          variant="subtitle2"
          color="secondary"
          style={{ marginBottom: '0.5rem' }}
        >
          <strong>What sort of Jira workflow does your team use?</strong>
        </Typography>
        <RadioGroup
          row
          value={config.jiraWorkflow.workflowType}
          onChange={e =>
            handleConfigChange('jiraWorkflow.workflowType', e.target.value)
          }
        >
          {[
            {
              label: 'We use a simplified workflow',
              value: 'simplified',
            },
            {
              label: 'We use a complex workflow',
              value: 'complex',
            },
            {
              label: 'Custom JQL Query',
              value: 'custom',
            },
          ].map(({ label, value }) => (
            <FormControlLabel
              key={value}
              control={<Radio />}
              label={label}
              value={value}
            />
          ))}
        </RadioGroup>
      </ItemContainer>
      {['custom', 'custom-jql'].includes(config.jiraWorkflow.workflowType) &&
        config.jiraWorkflow.queries && (
          <ItemContainer>
            <Typography
              variant="subtitle2"
              color="secondary"
              style={{ marginBottom: '0.5rem' }}
            >
              <strong>
                Customize the JQL we use to query your Jira tickets
              </strong>
            </Typography>

            {Object.keys(config.jiraWorkflow.queries).map(queryKey => {
              const key = `jiraWorkflow.queries.${queryKey}`;
              return (
                <KeyValueRow
                  value={
                    config.jiraWorkflow.queries?.[queryKey]?.customJql || ''
                  }
                  title={queryKey}
                  disableTitle
                  key={key}
                  handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleConfigChange(
                      `jiraWorkflow.queries.${queryKey}.customJql`,
                      e.target.value,
                    )
                  }
                  validationErrors={
                    validationErrors.find(x => x.path.includes(key)) || {
                      errors: [],
                      path: '',
                    }
                  }
                />
              );
            })}
          </ItemContainer>
        )}
      <FilterList
        label="Add specific key values where your team considers a ticket to be completed"
        value={config.jiraWorkflow.completedWorkflowCondition}
        target="jiraWorkflow.completedWorkflowCondition"
        onChange={handleConfigChange}
        disableChange={config.jiraWorkflow.workflowType === 'simplified'}
        validationErrors={validationErrors}
        options={{
          disableRemoveButton:
            config.jiraWorkflow.completedWorkflowCondition.length === 1,
        }}
      />
      <FilterList
        label="Add specific key values where your team considers a ticket to be in progress"
        value={config.jiraWorkflow.inProgressWorkflowCondition}
        target="jiraWorkflow.inProgressWorkflowCondition"
        onChange={handleConfigChange}
        disableChange={config.jiraWorkflow.workflowType === 'simplified'}
        validationErrors={validationErrors}
        options={{
          disableRemoveButton:
            config.jiraWorkflow.inProgressWorkflowCondition.length === 1,
        }}
      />
    </Box>
  );
};

export const ConfigurationDialogV2 = ({
  config,
  handleSubmit,
  defaultBaseQueries,
  canModify,
}: {
  config: Config;
  defaultBaseQueries: {
    avgBugResolutionTime: string;
    avgChangeLeadTime: string;
    highPriorityBugsIntroduced: string;
  };
  handleSubmit: (config: Config) => Promise<void>;
  canModify: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [newConfig, setNewConfig] = useState<Config>(structuredClone(config));
  const [validationErrors, setValidationErrors] = useState<
    {
      errors: Array<string>;
      path: string;
    }[]
  >([]);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setNewConfig(config);
    setOpen(false);
  };

  const handleChangeSideEffects = ({
    target,
    next,
    callback,
  }: {
    target: string;
    next: string | KeyValue[];
    callback: (target: string, value: any) => void;
  }) => {
    if (target === 'jiraWorkflow.workflowType') {
      if (next === 'simplified') {
        callback(
          'jiraWorkflow.completedWorkflowCondition',
          DEFAULT_FILTER_ENTRIESV2.jiraCompletedFilters,
        );
        callback(
          'jiraWorkflow.inProgressWorkflowCondition',
          DEFAULT_FILTER_ENTRIESV2.jiraStartedFilters,
        );
        callback('jiraWorkflow.queries', defaultBaseQueries);
      }
      if (next === 'custom' || next === 'custom-jql') {
        const {
          avgBugResolutionTime,
          avgChangeLeadTime,
          highPriorityBugsIntroduced,
        } = defaultBaseQueries;
        callback('jiraWorkflow.queries', {
          avgChangeLeadTime: {
            customJql: avgChangeLeadTime,
          },
          avgBugResolutionTime: {
            customJql: avgBugResolutionTime,
          },
          highPriorityBugsIntroduced: {
            customJql: highPriorityBugsIntroduced,
          },
        });
      }
    }
  };

  const handleChangeConfig = (key: string, value: any) => {
    const updated = { ...newConfig };
    handleChangeSideEffects({
      callback: handleChangeConfig,
      target: key,
      next: value,
    });
    set(updated, key, value);
    setNewConfig(updated);
  };

  const validateForm = () => {
    // make an array of objects with a path and error message
    const errors: { path: string; errors: string[] }[] = [];
    const addError = (path: string, error: string) => {
      const existing = errors.find(e => e.path === path);
      if (existing) {
        existing.errors.push(error);
      } else {
        errors.push({ path, errors: [error] });
      }
    };

    newConfig.jiraWorkflow.completedWorkflowCondition.forEach(
      (record, index) => {
        if (!record.key || !record.value) {
          addError(
            `jiraWorkflow.completedWorkflowCondition[${index}]`,
            'All rows must have key and value',
          );
        }
      },
    );

    newConfig.jiraWorkflow.inProgressWorkflowCondition.forEach(
      (record, index) => {
        if (!record.key || !record.value) {
          addError(
            `jiraWorkflow.inProgressWorkflowCondition[${index}]`,
            'All rows must have key and value',
          );
        }
      },
    );

    newConfig.deploymentWorkflow.branchFilters.forEach((record, index) => {
      if (record.key !== 'branch') {
        addError(
          `deploymentWorkflow.branchFilters[${index}]`,
          'Key must be "branch"',
        );
      }
    });

    newConfig.deploymentWorkflow.branchFilters.forEach((record, index) => {
      if (!record.value) {
        addError(
          `deploymentWorkflow.branchFilters[${index}]`,
          'Branch value required',
        );
      }
    });

    newConfig.deploymentWorkflow.pipelineFilters.forEach((record, index) => {
      if (!record.key || !record.value) {
        addError(
          `deploymentWorkflow.pipelineFilters[${index}]`,
          'All rows must have key and value',
        );
      }
    });

    if (newConfig.jiraWorkflow.workflowType === 'custom') {
      const queries = newConfig.jiraWorkflow.queries || ({} as CustomJqlQuery);
      Object.entries(queries).forEach(value => {
        const [key, customQuery] = value;
        if (!customQuery.customJql) {
          addError(
            `jiraWorkflow.queries.${key}.customJql`,
            'Custom JQL query required',
          );
        }
      });
    }

    setValidationErrors(errors);
    return errors;
  };

  const handleSubmitConfig = async () => {
    const errors = validateForm();

    if (errors.length) {
      return;
    }

    await handleSubmit(newConfig);
    closeDialog();
  };

  return (
    <>
      <IconButton size="small" onClick={openDialog}>
        <SettingsIcon />
      </IconButton>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={closeDialog}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">
          <Box display="flex" justifyContent="center">
            DORA Metrics Configuration
          </Box>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogForm
            handleConfigChange={handleChangeConfig}
            config={newConfig}
            validationErrors={validationErrors}
          />
        </DialogContent>
        <DialogActions>
          <Tooltip
            placement="bottom"
            title={
              canModify
                ? 'Update config'
                : 'Only Github team owners can modify this'
            }
          >
            <span>
              <Button
                onClick={handleSubmitConfig}
                startIcon={<DoneIcon />}
                color="primary"
                disabled={!canModify}
              >
                Apply
              </Button>
            </span>
          </Tooltip>
          <Button
            onClick={closeDialog}
            startIcon={<CloseIcon />}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

import React, { useRef } from 'react';
import { Grid, GridSize, Typography } from '@material-ui/core';
import { InfoCard, Progress } from '@backstage/core-components';
import { catalogApiRef, useEntity } from '@backstage/plugin-catalog-react';
import { DoraLegend } from '../Cards/LegendCard';
import { DoraMetricCard } from '../Cards/DoraMetricCard';
import { useTheme } from '@material-ui/core/styles';
import { BackstageTheme } from '@backstage/theme';
import { InfoDialog } from '../Modals/InformationDialog';
import {
  Config,
  KeyValue,
  DoraApiResult,
} from '@internal/backstage-plugin-dora-metrics-backend';
import {
  discoveryApiRef,
  fetchApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import Alert from '@material-ui/lab/Alert';
import {
  cleanQueryString,
  getDoraSettingsRef,
  groupFilterByName,
} from '../../utils';
import {
  Entity,
  getCompoundEntityRef,
} from '@backstage/catalog-model';
import {
  DoraMetricsClient,
  JiraWorkflowTypes,
  KeyValueType,
} from '../../api/backend';
import {
  ConfigurationDialogV2,
  DEFAULT_FILTER_ENTRIESV2,
} from '../Modals/ConfigurationDialogV2';
import useAsync from 'react-use/esm/useAsync';
import Box from '@material-ui/core/Box';
import type { GetEntitiesByRefsResponse } from '@backstage/catalog-client';

export const GITHUB_DEPLOYMENT_JOB = 'github.com/deployment-job-name';
export const GITHUB_DEPLOYMENT_BRANCH = 'github.com/deployment-branch';
export const GITHUB_DEPLOYMENT_PIPELINE = 'github.com/deployment-pipeline';
export const GITHUB_REPO_SLUG = 'github.com/project-slug';
export const JIRA_COMPONENT_ANNOTATION = 'jira/component';
export const JIRA_PROJECT_KEY_ANNOTATION = 'jira/project-key';
export const DEFAULT_BRANCH_REGEX = /\/tree\/([^/]+)/;
export const DEFAULT_BRANCH = 'main';

export type FilteredDoraProject = {
  jiraComponent: string;
  repositorySlug: string;
  deploymentPipeline: string;
  deploymentJobName: string;
  hasMultipleOwners: boolean;
};

interface Committer {
  date: string;
  name: string;
  email: string;
  date_timestamp: number;
}

interface Author {
  name: string;
  email: string;
}

interface Commit {
  committer: Committer;
  author: Author;
  message: string;
  sha: string;
}

interface Repository {
  path: string;
  scheme: string;
  name: string;
  host: string;
  id: string;
}

interface Git {
  commit: Commit;
  name: string;
  default_branch: string;
  repository_url: string;
  repository: Repository;
  branch: string;
}

interface Github {
  conclusion: string;
  html_url: string;
  run_attempt: string;
  event: string;
  app_id: string;
}

interface Pipeline {
  number: number;
  name: string;
  fingerprint: string;
  id: string;
  url: string;
}

interface Job {
  name: string;
  id: string;
  url: string;
}

interface Provider {
  instance: string;
  name: string;
}

interface Ci {
  pipeline: Pipeline;
  job: Job;
  provider: Provider;
  status: string;
}

export interface PipelineAttributes {
  duration: number;
  github: Github;
  git: Git;
  _top_level: number;
  ci: Ci;
  start: number;
  source: string;
  env?: string;
  team?: string;
  service?: string;
}

interface RootAttributes {
  attributes: PipelineAttributes;
  ci_level: string;
  tags: string[];
}

interface RootObject {
  id: string;
  type: string;
  attributes: RootAttributes;
  start: number;
}

export type DatadogPipelineResult = RootObject;

export type DatadogDoraPayload = {
  data: DatadogPipelineResult[];
  links: {
    next: string;
  };
};

interface IssueFields {
  resolutiondate: string;
  created: string;
  timeToResolve?: string;
}

export type JiraIssue = {
  id: string;
  key: string;
  self: string;
  expand: string;
  changelog: {
    histories: Array<History>;
  };
  fields: IssueFields;
};

export type Metric = {
  metricUnit: string;
  metricValue: number;
  query?: string;
  data: JiraIssue[] | DatadogPipelineResult[];
  source?: 'jira' | 'datadog';
};

const useValidProjects = (
  entity: Entity | undefined,
): FilteredDoraProject | null => {
  if (entity === undefined) {
    return null;
  }

  return {
    hasMultipleOwners:
      (entity?.relations ?? []).filter(x => x.type === 'ownedBy').length > 1,
    jiraComponent:
      entity.metadata?.annotations?.[JIRA_COMPONENT_ANNOTATION] ||
      ('' as string),
    deploymentJobName:
      entity.metadata?.annotations?.[GITHUB_DEPLOYMENT_JOB] || ('' as string),
    deploymentPipeline:
      entity.metadata?.annotations?.[GITHUB_DEPLOYMENT_PIPELINE] || '',
    repositorySlug: entity.metadata?.annotations?.[GITHUB_REPO_SLUG] as string,
  };
};

const getComputedResolvedBugQuery = (
  projectKey: string,
  components: string[],
) => {
  const componentQuery = components.length
    ? `AND component in (${components.join(',')})`
    : '';

  return `
      resolutiondate > -4w
      AND issuetype = 'Bug'
      AND project = ${projectKey}
      AND (priority in (Highest, High, Blocker))
      AND "Environment[Dropdown]" = Production
      ${componentQuery}`;
};

const getComputedResolvedQuery = (
  workflowType: JiraWorkflowTypes,
  projectKey: string,
  components: string[],
  completedStatusFilters: KeyValueType[],
): string => {
  const groupedFilters = groupFilterByName(completedStatusFilters);

  const workflowQuery =
    workflowType === 'complex' && Object.keys(groupedFilters).length
      ? `AND (${Object.keys(groupedFilters)
        .map(key => `${key} in ("${groupedFilters[key].join('","')}")`)
        .join(' OR ')})`
      : '';

  const componentQuery = components.length
    ? `AND component in (${components.join(',')})`
    : '';

  const baseQuery = `
      resolutiondate > -4w 
      AND issuetype in (Story, Task) 
      AND project = ${projectKey}
      `;

  return `${baseQuery} ${workflowQuery} ${componentQuery}`;
};

const getComputedHighPriorityBugsIntroducedQuery = (
  projectKey: string,
  components: string[],
) => {
  const componentQuery = components.length
    ? `AND component in (${components.join(',')})`
    : '';

  const baseQuery = `
      created > -4w 
      AND issuetype = Bug
      AND project = ${projectKey}
      AND (priority in (Highest, High, Blocker))
      AND "Environment[Dropdown]" = Production`;

  return `${baseQuery} ${componentQuery}`;
};

const getDefaultBaseQueries = ({
  projectKey,
  components,
  jiraStartedFilters,
}: {
  projectKey: string;
  components: string[];
  jiraStartedFilters: KeyValue[];
}): {
  avgChangeLeadTime: string;
  avgBugResolutionTime: string;
  highPriorityBugsIntroduced: string;
} => {
  return {
    avgChangeLeadTime: cleanQueryString(
      getComputedResolvedQuery(
        'simplified',
        projectKey,
        components,
        jiraStartedFilters,
      ),
    ),
    avgBugResolutionTime: cleanQueryString(
      getComputedResolvedBugQuery(projectKey, components),
    ),
    highPriorityBugsIntroduced: cleanQueryString(
      getComputedHighPriorityBugsIntroducedQuery(projectKey, components),
    ),
  };
};


export const DoraMetrics = () => {
  const { entity } = useEntity();
  const identityApi = useApi(identityApiRef);
  const catalogApi = useApi(catalogApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);
  const doraMetricsApi = new DoraMetricsClient({
    discoveryApi,
    fetchApi,
  });
  const [canModify, setCanModify] = React.useState(false);
  const { palette } = useTheme<BackstageTheme>();

  const settingsKey = getDoraSettingsRef(getCompoundEntityRef(entity));
  const defaultFilters = structuredClone(DEFAULT_FILTER_ENTRIESV2);
  const [settings, setSettings] = React.useState<Config>({
    deploymentWorkflow: {
      branchFilters: defaultFilters.branchFilters,
      pipelineFilters: defaultFilters.pipelineFilters,
    },
    jiraWorkflow: {
      workflowType: 'simplified',
      completedWorkflowCondition: defaultFilters.jiraCompletedFilters,
      inProgressWorkflowCondition: defaultFilters.jiraStartedFilters,
    },
  });

  const [projectLoading, setProjectLoading] = React.useState(false);
  const [projectError, setProjectError] = React.useState<string | null>(null);
  const [projectDoraMetrics, setProjectDoraMetrics] =
    React.useState<DoraApiResult | null>(null);
  const [filteredProjects, setFilteredProjects] = React.useState<
    FilteredDoraProject[] | null
  >(null);

  const jiraProjectKey = useRef(
    (entity.metadata?.annotations?.[JIRA_PROJECT_KEY_ANNOTATION] ||
      '') as string,
  );

  const handleSubmit = async (config: Config) => {
    try {
      await doraMetricsApi.updateTeamConfiguration(
        getCompoundEntityRef(entity),
        {
          config: [config],
        },
      );
    } catch (e) {
      throw new Error('Failed to update configuration');
    }
    setSettings(config);
  };

  useAsync(async () => {
    if (!entity) {
      return;
    }

    const defaultBaseQueries = getDefaultBaseQueries({
      projectKey: jiraProjectKey.current,
      // We are leaving this empty for now, as we will generate this on the backend from now
      components: [],
      jiraStartedFilters: defaultFilters.branchFilters,
    });

    const legacyConfig = JSON.parse(
      localStorage.getItem('doraMetrics') || '{}',
    );

    const storedConfig = await doraMetricsApi
      .fetchTeamConfiguration(getCompoundEntityRef(entity))
      .catch(_ => undefined);

    const defaultConfig = {
      workFlowType: 'simplified',
      entries: defaultFilters,
    };

    const legacyTeamConfig = legacyConfig?.[settingsKey] || defaultConfig;
    const convertedConfig = doraMetricsApi.mapOldConfigToNew(
      legacyTeamConfig,
      defaultBaseQueries,
    );

    const doraTeamConfig =
      (storedConfig?.config?.[0] as Config) || convertedConfig;

    const identity = await identityApi.getBackstageIdentity();
    const identityEntity = await catalogApi.getEntityByRef(
      identity.userEntityRef,
    );

    const isOwner = entity.metadata?.annotations?.[
      'github/team-admins'
    ].includes(
      identityEntity?.metadata.annotations?.['github.com/user-login'] || '',
    );

    if (isOwner) {
      setCanModify(true);
    }
    if (isOwner && !storedConfig) {
      await doraMetricsApi
        .updateTeamConfiguration(getCompoundEntityRef(entity), {
          config: [doraTeamConfig],
        })
        .catch();
    }
    if (!doraTeamConfig.jiraWorkflow.queries) {
      const {
        avgBugResolutionTime,
        avgChangeLeadTime,
        highPriorityBugsIntroduced,
      } = defaultBaseQueries;
      doraTeamConfig.jiraWorkflow.queries = {
        avgBugResolutionTime: {
          customJql: avgBugResolutionTime,
        },
        avgChangeLeadTime: {
          customJql: avgChangeLeadTime,
        },
        highPriorityBugsIntroduced: {
          customJql: highPriorityBugsIntroduced,
        },
      };
    }
    setSettings(doraTeamConfig);
  }, [entity.metadata?.uid]);

  useAsync(async () => {
    if (!entity) {
      return;
    }

    setProjectLoading(true);
    setProjectError(null);

    const filteredRefs = entity.relations
      ?.filter(relation => relation.targetRef.startsWith('component:'))
      .map(relation => relation.targetRef) as string[];

    const { items }: GetEntitiesByRefsResponse =
      await catalogApi.getEntitiesByRefs({ entityRefs: filteredRefs });

    if (items === undefined) {
      throw new Error('This team has no components');
    }

    setFilteredProjects(
      items
        .map(useValidProjects)
        .filter(Boolean) as NonNullable<FilteredDoraProject>[],
    );

    try {
      const doraMetricResponse = await doraMetricsApi.getLatestDoraMetrics(
        getCompoundEntityRef(entity),
      );
      setProjectDoraMetrics(doraMetricResponse);
    } catch (err) {
      setProjectError("Couldn't fetch DORA metrics");
    } finally {
      setProjectLoading(false);
    }
  }, [entity]);

  const launchDarklyEnabled = entity.metadata?.annotations?.['launchdarkly.com/project-key'];

  const gridSizes: {
    [key: string]: GridSize;
  } = launchDarklyEnabled ? {
    lg: 4,
    md: 6,
    sm: 12,
    xs: 12,
  } : {
      lg: 3,
      md: 6,
      sm: 12,
      xs: 12,
    };

  return (
    <InfoCard
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <Box>
            <Typography variant="h5">DORA Metrics</Typography>
            <Typography variant="body2">
              Deployment branch:{' '}
              <strong>
                {settings.deploymentWorkflow.branchFilters
                  .map((filter: KeyValue) => filter.value)
                  .join(', ')}
              </strong>
            </Typography>
          </Box>
          <DoraLegend palette={palette} />
        </div>
      }
      action={[
        <InfoDialog key="info" />,
        <ConfigurationDialogV2
          key={JSON.stringify(settings)}
          canModify={canModify}
          handleSubmit={handleSubmit}
          config={settings}
          defaultBaseQueries={getDefaultBaseQueries({
            projectKey: jiraProjectKey.current,
            // We are leaving this empty for now, as we will generate this on the backend from now
            components:
              (
                (filteredProjects || [])
                  .map((x: { jiraComponent: any }) => x.jiraComponent)
                  .filter(Boolean)),
            jiraStartedFilters: defaultFilters.jiraStartedFilters,
          })}
        />,
      ]}
      deepLink={{
        title: 'View historical data',
        link: `https://app.datadoghq.eu/dashboard/hfy-cr2-n4y/platform-dora-template?fromUser=true&refresh_mode=sliding&tpl_var_env[0]=prod&tpl_var_team[0]=${entity.metadata.name}`,
      }}
    >
      {projectLoading ? <Progress /> : null}
      {projectError ? <Alert severity="error">{projectError}</Alert> : null}
      {projectDoraMetrics?.metrics && !projectLoading ? (
        <Grid
          container
          alignItems="flex-start"
          justifyContent="space-around"
          direction="row"
          spacing={1}
        >
          {launchDarklyEnabled ? (
            <>
              <Grid item {...gridSizes}>
                <DoraMetricCard
                  palette={palette}
                  title="Customer Lead Time"
                  description="The time from when a Jira ticket is in progress until the feature is released to users."
                  metrics={
                    projectDoraMetrics?.metrics.avgCustomerLeadTime
                  }
                  metricName="avgCustomerLeadTime"
                  indicators={{
                    elite: 1,
                    high: 7,
                    medium: 30,
                    low: 60,
                  }}
                />
              </Grid>
              <Grid item {...gridSizes}>
                <DoraMetricCard
                  palette={palette}
                  title="Waiting lead Time"
                  description="The time a feature remains in production but isn’t released to users"
                  metrics={
                    projectDoraMetrics?.metrics.avgWaitingLeadTime
                  }
                  metricName="avgWaitingLeadTime"
                  indicators={{
                    elite: 1,
                    high: 7,
                    medium: 30,
                    low: 60,
                  }}
                />
              </Grid>
              <Grid item {...gridSizes}>
                <DoraMetricCard
                  palette={palette}
                  title="Engineering Lead Time"
                  description="Average total engineering time (from In Progress to Done)"
                  metrics={projectDoraMetrics?.metrics.avgLeadTimeForChange}
                  metricName="avgLeadTimeForChange"
                  indicators={{
                    elite: 1,
                    high: 7,
                    medium: 30,
                    low: 60,
                  }}
                />
              </Grid>
            </>
          ) : (
            <Grid item {...gridSizes}>
              <DoraMetricCard
                palette={palette}
                title="Lead time for change"
                description="Lead time for new features to be rolled out"
                metrics={projectDoraMetrics?.metrics.avgLeadTimeForChange}
                metricName="avgLeadTimeForChange"
                indicators={{
                  elite: 1,
                  high: 7,
                  medium: 30,
                  low: 60,
                }}
              />
            </Grid>
          )}
          <Grid item {...gridSizes}>
            <DoraMetricCard
              palette={palette}
              title="Mean time to repair"
              metrics={projectDoraMetrics?.metrics.avgMeanTimeToRepair}
              metricName="avgMeanTimeToRepair"
              description="Total time taken to resolve critical bugs in production"
              indicators={{
                elite: 0.05,
                high: 1,
                medium: 7,
                low: 30,
              }}
            />
          </Grid>
          <Grid item {...gridSizes}>
            <DoraMetricCard
              palette={palette}
              title="Change fail rate"
              description="Percentage of critical bugs introduced from deployments"
              metrics={projectDoraMetrics?.metrics.changeFailRate}
              metricName="changeFailRate"
              indicators={{
                elite: 5,
                high: 10,
                medium: 15,
                low: 50,
              }}
            />
          </Grid>
          <Grid item {...gridSizes}>
            <DoraMetricCard
              palette={palette}
              title="Deployment frequency"
              description="Average deployments per working day"
              metrics={projectDoraMetrics?.metrics.deploymentFrequency}
              metricName="deploymentFrequency"
              indicators={{
                elite: 1,
                high: 0.2,
                medium: 0.1,
                low: 0.045,
                invert: true,
              }}
            />
          </Grid>
        </Grid>
      ) : null}
    </InfoCard>
  );
};
